import imgHeader from "../assets/img/header.png";

export default function Header() {


    return (
        <header className="App-header">
            <div className="breakout">
                <a target="_blank" rel="noreferrer" href="http://gbb3.levelupdice.net">GBB Series 3</a>
            </div>
            <img className="img-header" src={imgHeader} alt="Glyphic Blind Bag Series 3.5"
                 title="Glyphic Blind Bag Series 3.5"/>
        </header>
    )
}
