import React from 'react';
import "@fancyapps/ui/dist/fancybox.css";
import DiceImage from "./dice-image";
import {FaCircle, FaStar} from "react-icons/fa";
import dice from '../data/data-dice';

export default class DiceContainer extends React.Component {

    state = {
        tally: JSON.parse(localStorage.getItem('tally')) || {
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
            '24': 0,
            '25': 0,
            '26': 0,
            '27': 0,
            '28': 0,
            '29': 0,
            '30': 0,
            '31': 0,
            '32': 0,
            '33': 0,
            '34': 0,
            '35': 0,
            '36': 0,
            '37': 0,
            '38': 0,
            '39': 0,
            '40': 0,
            '41': 0,
            '42': 0,
            '43': 0,
            '44': 0,
            '45': 0,
            '46': 0,
            '47': 0,
            '48': 0,
            '49': 0,
            '50': 0,
            '51': 0,
            '52': 0,
            '53': 0,
            '54': 0,
            '55': 0,
            '56': 0,
            '57': 0,
            '58': 0,
            '59': 0,
            '60': 0,
            '61': 0,
            '62': 0,
            '63': 0,
            '64': 0,
        },
        rarity: '',
        type: '',
        owned: 'dice-card',
        message: ''
    }

    //check if they a checklist in localstorage
    //if so, use data to make a new tally and set it to state and localstorage
    async componentDidMount() {
        if (localStorage.getItem('checklist')) {
            console.log("Converting...")
            let checklist_string = localStorage.getItem('checklist');
            checklist_string = checklist_string.replaceAll('[', '');
            checklist_string = checklist_string.replaceAll(']', '');
            let checklist_arr = checklist_string.split(',');
            let newTally = {};
            for (let i = 0; i < checklist_arr.length; i++) {
                //when i = 0, state.tally=1
                newTally[i + 1] = checklist_arr[i];
            }
            await this.setState({tally: newTally})
            localStorage.removeItem('checklist');
            localStorage.setItem('tally', JSON.stringify(this.state.tally));
        }
    }

    render() {
        let handleChecklistChange = async (e) => {
            const dieId = e.target.dataset.id.toString();
            const value = parseInt(e.target.value);
            let newState = this.state.tally;
            newState[dieId] = value;
            await this.setState({tally: newState});
            localStorage.setItem('tally', JSON.stringify(this.state.tally));
        }

        let handleFilterChange = async (e) => {
            let diceCards = document.querySelectorAll('.dice-card');
            if (e.target.dataset.filterType === "rarity") {

                let rarityButtons = document.querySelectorAll('.filters .rarity button');
                for (let i = 0; i < rarityButtons.length; i++) {
                    rarityButtons[i].classList.remove('active');
                }
                e.target.classList.add('active');

                let rarity = e.target.innerHTML;
                console.log(rarity);
                if (rarity === 'all') {
                    rarity = '';
                }
                await this.setState({rarity: rarity})

            } else if (e.target.dataset.filterType === "type") {

                let filterButtons = document.querySelectorAll('.filters .type button');
                for (let i = 0; i < filterButtons.length; i++) {
                    filterButtons[i].classList.remove('active');
                }
                e.target.classList.add('active');

                let type = e.target.innerHTML;
                if (type === 'all') {
                    type = '';
                } else if (type === 'foiled') {
                    type = 'foil';
                }
                await this.setState({type: type});
            } else if (e.target.dataset.filterType === 'owned') {
                let filterButtons = document.querySelectorAll('.filters .owned button');
                for (let i = 0; i < filterButtons.length; i++) {
                    filterButtons[i].classList.remove('active');
                }
                e.target.classList.add('active');

                let owned = e.target.innerHTML;
                if (owned === 'all') {
                    owned = 'dice-card';
                } else if (owned === 'yes') {
                    owned = 'checked';
                } else if (owned === 'no') {
                    owned = 'unchecked'
                }
                await this.setState({owned: owned});
            }

            let hidden = 0;

            for (let i = 0; i < diceCards.length; i++) {

                if (diceCards[i].dataset.tagsRarity.startsWith(this.state.rarity) &&
                    diceCards[i].dataset.tagsType.indexOf(this.state.type) > -1 &&
                    diceCards[i].classList.contains(this.state.owned)
                ) {
                    diceCards[i].style.display = "block";
                } else {
                    diceCards[i].style.display = "none";
                    hidden++
                }
            }

            if (hidden === 64) {
                this.setState({message: "No dice match that filter. Try something else!"})
            } else {
                this.setState({message: ""})
            }
        }

 /*       const diceChance = {
            common: {
                "regular": 2.11,
                "foil": 0.21
            },
            uncommon: {
                "regular": 1.89,
                "foil": 0.19
            },
            rare: {
                "regular": 1.05,
                "foil": 0.11
            }
        }
*/

        return (
            <div>
                <div className="filters">
                    <div className="filter rarity">
                        <h4>Rarity:</h4>
                        <button data-filter-type="rarity" onClick={handleFilterChange} className="all active">all
                        </button>
                        <button data-filter-type="rarity" onClick={handleFilterChange} className="common">common
                        </button>
                        <button data-filter-type="rarity" onClick={handleFilterChange} className="uncommon">uncommon
                        </button>
                        <button data-filter-type="rarity" onClick={handleFilterChange} className="rare">rare
                        </button>
                    </div>
                    <div className="filter type">
                        <h4>Type:</h4>
                        <button data-filter-type="type" onClick={handleFilterChange} className="all active">all
                        </button>
                        <button data-filter-type="type" onClick={handleFilterChange} className="regular">regular
                        </button>
                        <button data-filter-type="type" onClick={handleFilterChange} className="foiled">foiled
                        </button>
                    </div>
                    <div className="filter owned">
                        <h4>Owned: </h4>
                        <button data-filter-type="owned" onClick={handleFilterChange} className="all active">all
                        </button>
                        <button data-filter-type="owned" onClick={handleFilterChange} className="yes_owned">yes
                        </button>
                        <button data-filter-type="owned" onClick={handleFilterChange} className="not_owned">no
                        </button>
                    </div>

                </div>

                <div className="dice-container">

                    {dice.map((die, index) => (
                        <div
                            className={this.state.tally[index + 1] > 0 ? "checked dice-card" : "unchecked dice-card"}
                            data-tags-rarity={die.rarity} data-tags-type={die.type}
                            key={die.id + "-" + die.type}>
                            <input className="tracker" type="number" min="0" max="100" data-id={index + 1}
                                   value={this.state.tally[index + 1]} onChange={handleChecklistChange}/>
                            <div style={{overflow: "hidden"}}>
                                <DiceImage die={die}/>
                            </div>
                            <h4>{die.type === "regular" ? <FaCircle/> : <FaStar/>} {die.id}</h4>
                            <h4>{die.type === "foil" ? "(Foiled)" : ""} {die.design}</h4>
                            <h4>{die.stone}</h4>
                        </div>
                    ))}
                    <h3 className="message">{this.state.message}</h3>
                </div>
            </div>
        )
    }
}

/* Insert this under {die.stone} if rarity display needed

<h6>
({diceChance[die.rarity][die.type]}% to get)
</h6>

*/


