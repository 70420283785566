import { Link } from 'react-router-dom';

export default function Links() {

    return (
        <div className="links">
            <div className="link">
                <h3>Find in your Friendly Local Game Store (FLGS)</h3>
                <div className="link-as">
                    <Link to="/flgs">FLGS</Link>
                </div>
            </div>
            <div className="link shop">
                <h3>Direct</h3>
                <p>Our warehouse goblin managed to hide away a small amount if you can't get to your
                    FLGS!</p>
                <div className="link-as">
                    <a target="_blank" rel="noreferrer" href="https://usa.levelupdice.net/collections/glyphic-blind-bags">INT'L</a>
                    <a target="_blank" rel="noreferrer" href="https://aus.levelupdice.net/collections/glyphic-blind-bags">AUS</a>
                </div>
            </div>
            <div className="link social">
                <h3>Socials</h3>
                <p>Discuss, trade, and show off your collection with others!</p>
                <div className="link-as">
                    <a target="_blank" rel="noreferrer"
                       href="https://www.facebook.com/groups/184632523167818">Facebook</a>
                </div>
            </div>
        </div>
    )
}


