const dice =[
   
    {
        "id": 1,
        "type": "regular",
        "design": "Riku",
        "stone": "Brown Snowflake Jasper",
        "rarity": "common"
    },
    {
        "id": 1,
        "type": "foil",
        "design": "Riku",
        "stone": "Brown Snowflake Jasper",
        "rarity": "common"
    },
    
    {
        "id": 2,
        "type": "regular",
        "design": "Era",
        "stone": "Lapis Lazuli",
        "rarity": "common"
    },
    {
        "id": 2,
        "type": "foil",
        "design": "Era",
        "stone": "Lapis Lazuli",
        "rarity": "common"
    },
    {
        "id": 3,
        "type": "regular",
        "design": "Agni",
        "stone": "Red Granite",
        "rarity": "common"
    },
    {
        "id": 3,
        "type": "foil",
        "design": "Agni",
        "stone": "Red Granite",
        "rarity": "common"
    },
 
    {
        "id": 4,
        "type": "regular",
        "design": "Meri",
        "stone": "Blue Bahia Granite",
        "rarity": "common"
    },
    {
        "id": 4,
        "type": "foil",
        "design": "Meri",
        "stone": "Blue Bahia Granite",
        "rarity": "common"
    },
    {
        "id": 5,
        "type": "regular",
        "design": "ζάρια",
        "stone": "Howlite",
        "rarity": "common"
    },
    {
        "id": 5,
        "type": "foil",
        "design": "ζάρια",
        "stone": "Howlite",
        "rarity": "common"
    },
    {
        "id": 6,
        "type": "regular",
        "design": "R",
        "stone": "Bloodstone",
        "rarity": "common"
    },
    {
        "id": 6,
        "type": "foil",
        "design": "R",
        "stone": "Bloodstone",
        "rarity": "common"
    },
    {
        "id": 7,
        "type": "regular",
        "design": "Weaver",
        "stone": "Pink Cats Eye",
        "rarity": "common"
    },
    {
        "id": 7,
        "type": "foil",
        "design": "Weaver",
        "stone": "Pink Cats Eye",
        "rarity": "common"
    },
    {
        "id": 8,
        "type": "regular",
        "design": "Duckie",
        "stone": "Lemon Jade",
        "rarity": "common"
    },
    {
        "id": 8,
        "type": "foil",
        "design": "Duckie",
        "stone": "Lemon Jade",
        "rarity": "common"
    },
    {
        "id": 9,
        "type": "regular",
        "design": "Tua",
        "stone": "Malachite",
        "rarity": "common"
    },
    {
        "id": 9,
        "type": "foil",
        "design": "Tua",
        "stone": "Malachite",
        "rarity": "common"
    },
    {
        "id": 10,
        "type": "regular",
        "design": "POP",
        "stone": "Light Blue K9 Glass",
        "rarity": "common"
    },
    {
        "id": 10,
        "type": "foil",
        "design": "POP",
        "stone": "Light Blue K9 Glass",
        "rarity": "common"
    },
    
    {
        "id": 11,
        "type": "regular",
        "design": "Vortex",
        "stone": "Obsidian",
        "rarity": "uncommon"
    },
    {
        "id": 11,
        "type": "foil",
        "design": "Vortex",
        "stone": "Obsidian",
        "rarity": "uncommon"
    },   
    {
        "id": 12,
        "type": "regular",
        "design": "Icing",
        "stone": "Opalite",
        "rarity": "uncommon"
    },
    {
        "id": 12,
        "type": "foil",
        "design": "Icing",
        "stone": "Opalite",
        "rarity": "uncommon"
    },
    
    
    {
        "id": 13,
        "type": "regular",
        "design": "Arlecchino",
        "stone": "Blue Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 13,
        "type": "foil",
        "design": "Arlecchino",
        "stone": "Blue Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 14,
        "type": "regular",
        "design": "O.o",
        "stone": "Green Turquoise",
        "rarity": "uncommon"
    },
    {
        "id": 14,
        "type": "foil",
        "design": "O.o",
        "stone": "Green Turquoise",
        "rarity": "uncommon"
    },
    
    {
        "id": 15,
        "type": "regular",
        "design": "Mix-It",
        "stone": "Red Adventurine",
        "rarity": "uncommon"
    },
    {
        "id": 15,
        "type": "foil",
        "design": "Mix-It",
        "stone": "Red Adventurine",
        "rarity": "uncommon"
    },
    {
        "id": 16,
        "type": "regular",
        "design": "Om-nom-nom",
        "stone": "Tiger's Eye",
        "rarity": "uncommon"
    },
    {
        "id": 16,
        "type": "foil",
        "design": "Om-nom-nom",
        "stone": "Tiger's Eye",
        "rarity": "uncommon"
    },
    {
        "id": 17,
        "type": "regular",
        "design": "JumpPupper",
        "stone": "Sonata",
        "rarity": "rare"
    },
    {
        "id": 17,
        "type": "foil",
        "design": "JumpPupper",
        "stone": "Sonata",
        "rarity": "rare"
    },
    {
        "id": 18,
        "type": "regular",
        "design": "Divination",
        "stone": "Holographic Glass",
        "rarity": "rare"
    },
    {
        "id": 18,
        "type": "foil",
        "design": "Divination",
        "stone": "Holographic Glass",
        "rarity": "rare"
    },
    {
        "id": 19,
        "type": "regular",
        "design": "Sugar Rush",
        "stone": "Tri-Color Turquoise",
        "rarity": "rare"
    },
    {
        "id": 19,
        "type": "foil",
        "design": "Sugar Rush",
        "stone": "Tri-Color Turquoise",
        "rarity": "rare"
    },
    
    {
        "id": 20,
        "type": "regular",
        "design": "Good-Bye",
        "stone": "Purple Cats Eye",
        "rarity": "rare"
    },
    {
        "id": 20,
        "type": "foil",
        "design": "Good-Bye",
        "stone": "Purple Cats Eye",
        "rarity": "rare"
    }

]


export default dice;
