import React from 'react';
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
    arrows: false,
    keyboard: false,
    wheel: false
})

export default class DiceImage extends React.Component {
    state = {
        thumb: null,
        full: null,
        isFoil: this.props.die.type === "foil" ? "Foiled" : ""
    };

    componentDidMount() {
            const isFoil = this.props.die.type === "foil" ? "-foil" : "";
            import("../assets/img/thumbs/gbb35-" + this.props.die.id + isFoil + ".jpg")
                .then((image) => {
                this.setState({ thumb: image.default });
            })
                .catch(() => {
                    import("../assets/img/gbb35-X-foil-thumb.jpg").then((image) => {
                        this.setState({ thumb: image.default });
                    });
                });
            import("../assets/img/fullres/gbb35-" + this.props.die.id + isFoil +".jpg")
                .then((image) => {
                this.setState({ full: image.default });
            })
                .catch((image) => {
                    import("../assets/img/gbb35-X-foil-fullres.jpg").then((image) => {
                        this.setState({ full: image.default });
                    });
                })


    }

    render() {
        return this.state ? (
            <a data-fancybox="gallery"
               href={this.state.full}
               data-caption={this.state.isFoil + " " + this.props.die.design + " " + this.props.die.stone}>
                <img src={this.state.thumb}
                     alt={this.state.isFoil + " " + this.props.die.design + " " + this.props.die.stone}
                     title={this.state.isFoil + " " + this.props.die.design + " " + this.props.die.stone}/>
            </a>
        ) : null;
    }
}
